import { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch} from "react-router-dom";  // Import React Router
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import PrivacyPolicy from "./components/privacy"; // Import PrivacyPolicy component
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <Router>
      <div>
        {/* Conditionally render elements based on the route */}
        <Switch>
          {/* Route for PrivacyPolicy page */}
          <Route path="/privacy-policy">
            <Navigation />
            <Header data={landingPageData.Header} />
            {/* Only show PrivacyPolicy component */}
            <PrivacyPolicy />
          </Route>

          {/* Default route for the rest of the app */}
          <Route path="/">
            <Navigation />
            <Header data={landingPageData.Header} />
            <About data={landingPageData.About} />
            <Services data={landingPageData.Services} />
            <Contact data={landingPageData.Contact} />
          </Route>
        </Switch>
      </div>
    </Router>
  );
};


export default App;