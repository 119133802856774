
import { createRef, Component } from "react";

export class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navCollapsed: true
    };
   
    this.myRef = createRef();
    this.handleDocumentClick = this.handleDocumentClick.bind(this);
    this.isNavCollapsed = this.isNavCollapsed.bind(this);
    this.handleNavCollapse = this.handleNavCollapse.bind(this);
  }

  isNavCollapsed() {
    console.log("get state " + this.state.navCollapsed)
    return this.state.navCollapsed
  }

  handleNavCollapse() {
    console.log("Handle nav " + this.state.navCollapsed)
    this.setState({navCollapsed: true})
  }

  handleDocumentClick(e) {
 
    const container = this.state.navref;
    console.log("Click " + this.state.navref)

    if (e.target !== container && !container.contains(e.target)) {
      console.log("Click nav state " + this.state.navCollapsed)
      this.setState({navCollapsed: false})
    }
  }
  

  componentDidMount() {
    // document.addEventListener('click', this.handleDocumentClick, true)

    // //console.log("Component mount" + this.myRef.current)
    // this.setState({navref: this.myRef.current}, () => {
    //   console.log(this.state.navref)}
    // )
  }

  componentWillUnmount() {
  //  document.removeEventListener('click', this.handleDocumentClick, true)
  }


  render() {
    return (
      <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
        <div className='container'>
          <div className='navbar-header'>
            <button
              type='button'
              className='navbar-toggle collapsed'
              data-toggle='collapse'
              data-target='#bs-example-navbar-collapse-1'
              aria-controls="bs-example-navbar-collapse-1" aria-expanded="false" aria-label="Toggle navigation"
              //onClick={this.handleNavCollapse}
            >
              {' '}
              <span className='sr-only'>Toggle navigation</span>{' '}
              <span className='icon-bar'></span>{' '}
              <span className='icon-bar'></span>{' '}
              <span className='icon-bar'></span>{' '}
            </button>
            <a className='navbar-brand page-scroll' href='/#page-top'>
              Medclar 0723-513-564
            </a>
          
            
        
            {' '}
          </div>
          { this.isNavCollapsed() &&
          <div
            className='collapse navbar-collapse'
            id='bs-example-navbar-collapse-1'
            ref={this.myRef}
          >
            <ul className='nav navbar-nav navbar-right'>
  
              <li>
                <a href='/#about' className='page-scroll' data-toggle="collapse" data-target=".in" >
                  Despre
                </a>
              </li>
              <li>
                <a href='/#services' className='page-scroll' data-toggle="collapse" data-target=".in" >
                  Servicii
                </a>
              </li>
              <li>
                <a href='/#contact' className='page-scroll' data-toggle="collapse" data-target=".in" >
                  Contact
                </a>
              </li>
            </ul>
          </div>
          }
        </div>
      </nav>
    )
  }
}

