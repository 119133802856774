import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', color: '#333', lineHeight: '1.6' }}>
      {/* Central heading styling */}
      <h1 style={{ textAlign: 'center', fontSize: '28px', fontWeight: 'bold', marginBottom: '20px' }}>
        Politica de Confidențialitate
      </h1>

      <p>
        Această Politică de confidențialitate explică modul în care S.C. Medclar SRL colectează, utilizează și protejează datele cu caracter personal ale utilizatorilor atunci când accesați și utilizați site-ul nostru 
        <a href="https://medclar.ro" target="_blank" rel="noopener noreferrer" style={{ color: '#007bff', textDecoration: 'none' }}> https://medclar.ro/</a>
      </p>

      {/* Section headers styling */}
      <section>
        <h2 style={{ fontSize: '22px', fontWeight: '600', marginBottom: '10px' }}>Cine este responsabil de prelucrarea datelor dvs.?</h2>
        <p>
          S.C. Medclar SRL, cu sediul în România, este operatorul datelor cu caracter personal, conform legislației privind protecția datelor cu caracter personal, inclusiv GDPR, în ceea ce privește datele colectate și prelucrate prin intermediul Site-ului și/sau în contextul serviciilor oferite.
        </p>
        <p>
          Pentru orice întrebări legate de prelucrarea datelor personale, ne puteți contacta la: 
          <a href="mailto:popa.v.adriana@gmail.com" style={{ color: '#007bff', textDecoration: 'none' }}> popa.v.adriana@gmail.com</a>
        </p>
      </section>

      <section>
        <h2 style={{ fontSize: '22px', fontWeight: '600', marginBottom: '10px' }}>Ce date prelucrăm?</h2>
        <p>
          Prelucrăm datele personale pe care ni le furnizați atunci când contactați compania prin formularul de contact sau în alte interacțiuni. Informațiile colectate pot include: nume, prenume, adresă, număr de telefon, adresă de email și alte date relevante furnizate de utilizator.
        </p>
      </section>

      {/* Custom list styles */}
      <section>
        <h2 style={{ fontSize: '22px', fontWeight: '600', marginBottom: '10px' }}>Cum utilizăm datele dvs.?</h2>
        <ul style={{ listStyleType: 'disc', paddingLeft: '20px', marginTop: '10px' }}>
          <li style={{ marginBottom: '10px' }}>Furnizarea și administrarea serviciilor: Pentru a răspunde cerințelor dumneavoastră și pentru a vă oferi serviciile solicitate.</li>
          <li style={{ marginBottom: '10px' }}>Respectarea obligațiilor legale: Pentru a respecta reglementările aplicabile și pentru a îndeplini obligațiile noastre legale, cum ar fi raportările fiscale și arhivarea documentelor.</li>
        </ul>
      </section>

      <section>
        <h2 style={{ fontSize: '22px', fontWeight: '600', marginBottom: '10px' }}>Scopurile prelucrării datelor cu caracter personal</h2>
        <ul style={{ listStyleType: 'disc', paddingLeft: '20px', marginTop: '10px' }}>
          <li style={{ marginBottom: '10px' }}>Furnizarea și administrarea serviciilor: Informațiile pe care ni le oferiți (precum nume, adresă, date de contact, detalii de tranzacționare) sunt folosite pentru a răspunde solicitărilor dvs. și pentru a facilita interacțiunile necesare desfășurării serviciilor.</li>
          <li style={{ marginBottom: '10px' }}>Respectarea obligațiilor legale: Prelucrăm datele dvs. atunci când este necesar pentru a respecta obligațiile impuse de lege, inclusiv cerințele legate de raportări financiare, păstrarea înregistrărilor și arhivarea datelor.</li>
        </ul>
      </section>

      <section>
        <h2 style={{ fontSize: '22px', fontWeight: '600', marginBottom: '10px' }}>Cui dezvăluim datele?</h2>
        <ul style={{ listStyleType: 'disc', paddingLeft: '20px', marginTop: '10px' }}>
          <li style={{ marginBottom: '10px' }}>Entități sau persoane autorizate de noi care ne ajută să furnizăm serviciile, inclusiv pentru trimiterea comunicărilor comerciale (de exemplu, furnizori de centre de date, servicii de plată, platforme de e-mailing precum Google Mail).</li>
          <li style={{ marginBottom: '10px' }}>Autorități publice, guvernamentale sau judiciare, atunci când acest lucru este necesar pentru a respecta obligațiile legale.</li>
        </ul>
      </section>

      <section>
        <h2 style={{ fontSize: '22px', fontWeight: '600', marginBottom: '10px' }}>Păstrarea și securitatea datelor</h2>
        <p>
          Datele dvs. personale vor fi păstrate doar atât timp cât este necesar pentru îndeplinirea scopurilor pentru care au fost colectate, în conformitate cu procedurile noastre interne și cu cerințele legale privind arhivarea datelor.
        </p>
      </section>

      <section>
        <h2 style={{ fontSize: '22px', fontWeight: '600', marginBottom: '10px' }}>Drepturile dvs. în calitate de persoană vizată</h2>
        <p>
          Conform legislației în vigoare, aveți dreptul de a solicita accesul, rectificarea, ștergerea sau restricționarea prelucrării datelor dvs. personale, în condițiile prevăzute de lege. De asemenea, aveți dreptul de a vă opune prelucrării datelor pentru marketing direct și de a solicita portabilitatea acestora. În plus, aveți dreptul de a depune o plângere la autoritatea competentă de protecția datelor, în cazul în care considerați că drepturile dvs. au fost încălcate.
        </p>
      </section>

      <section>
        <h2 style={{ fontSize: '22px', fontWeight: '600', marginBottom: '10px' }}>Google Analytics</h2>
        <p>
          Site-ul nostru utilizează Google Analytics pentru a analiza modul în care utilizatorii interacționează cu acesta. Google Analytics colectează informații precum durata vizitei, paginile vizitate și altele, pentru a îmbunătăți experiența utilizatorilor.
        </p>
        <p>
          Pentru a opri urmărirea prin Google Analytics, aveți opțiunea de a instala un add-on pentru browserul dvs. web, disponibil aici: 
          <a href="http://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer" style={{ color: '#007bff', textDecoration: 'none' }}> http://tools.google.com/dlpage/gaoptout</a>. Mai multe informații despre cum Google prelucrează datele personale pot fi găsite în 
          <a href="https://policies.google.com/technologies/partner-sites" target="_blank" rel="noopener noreferrer" style={{ color: '#007bff', textDecoration: 'none' }}> Politica de confidențialitate Google</a>.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
